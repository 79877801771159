'use client';

import { useCallback } from 'react';

import { sendClickEvent } from '@withlocals/analytics';

import ArrowIcon from '../../icons/symbols/full-color/ArrowIcon';
import useNavButtonsDisabled from '../hooks/useNavButtonsDisabled';
import CSS from './CarouselButtons.module.scss';

interface CarouselButtonsProps {
  carouselRef: any;
  trackingName?: string;
  variant?: 'light' | 'dark';
}

const getLeftPosition = (listElement: HTMLElement) => {
  const listStyle = window.getComputedStyle(listElement);
  const element = listElement.children[0] as HTMLElement;
  const width = element.offsetWidth;
  const columnGap = parseFloat(listStyle.columnGap || '0');

  return width + columnGap;
};

function CarouselButtons({
  carouselRef,
  trackingName,
  variant = 'dark',
}: CarouselButtonsProps) {
  const { prevButtonDisabled, nextButtonDisabled } =
    useNavButtonsDisabled(carouselRef);

  const onNext = useCallback(() => {
    if (!carouselRef.current) {
      return;
    }
    if (trackingName) {
      sendClickEvent({ trackingName, text: 'next' });
    }
    const newPos = getLeftPosition(carouselRef.current);

    if (carouselRef.current.scrollBy) {
      carouselRef.current.scrollBy({
        left: newPos,
        behavior: 'smooth',
      });
    } else {
      carouselRef.current.scrollLeft = carouselRef.current.scrollLeft + newPos;
    }
  }, [carouselRef, trackingName]);

  const onPrevious = useCallback(() => {
    if (!carouselRef.current) {
      return;
    }
    if (trackingName) {
      sendClickEvent({ trackingName, text: 'prev' });
    }
    const newPos = getLeftPosition(carouselRef.current);

    if (carouselRef.current.scrollBy) {
      carouselRef.current.scrollBy({
        left: -newPos,
        behavior: 'smooth',
      });
    } else {
      carouselRef.current.scrollLeft = carouselRef.current.scrollLeft - newPos;
    }
  }, [carouselRef, trackingName]);

  if (prevButtonDisabled && nextButtonDisabled) {
    return null;
  }

  return (
    <fieldset
      aria-label="carousel buttons"
      className={CSS.ButtonContainer}
      aria-controls="carousel"
    >
      <button
        onClick={onPrevious}
        className={CSS.Button}
        disabled={prevButtonDisabled}
        value="previous"
        aria-label="previous"
      >
        <ArrowIcon
          size={16}
          direction="left"
          className={CSS.Icon}
          variant={variant === 'light' ? 'white' : 'black'}
        />
      </button>
      <button
        onClick={onNext}
        className={CSS.Button}
        disabled={nextButtonDisabled}
        value="next"
        aria-label="next"
      >
        <ArrowIcon
          size={16}
          direction="right"
          className={CSS.Icon}
          variant={variant === 'light' ? 'white' : 'black'}
        />
      </button>
    </fieldset>
  );
}

export default CarouselButtons;
