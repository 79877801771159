'use client';

import clsx from 'clsx';
import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import CSS from './Overlay.module.scss';

interface OverlayProps {
  portalId: string;
  onEsc: () => void;
  className?: any;
  onClick?: (event?: any) => void;
  children: ReactNode;
  theme?: 'light' | 'dark-translucent';
}

let prevScroll: number | undefined = undefined;

function Overlay({ onClick, children, className, onEsc, theme }: OverlayProps) {
  const [inBrowser, setInBrowser] = useState(false);
  useEffect(() => {
    setInBrowser(true);
    const onKeyUp = (event: any) => event.key === 'Escape' && onEsc();
    prevScroll = window.scrollY ?? 0;
    document.addEventListener('keydown', onKeyUp);
    document.body.style.top = `-${
      document?.scrollingElement?.scrollTop ?? 0
    }px`;
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';

    return () => {
      document.removeEventListener('keydown', onKeyUp);
      document.body.removeAttribute('style');
      if (prevScroll) {
        window.scrollTo(0, prevScroll);
        prevScroll = undefined;
      }
    };
  }, [onEsc]);

  const classes = clsx(CSS.Overlay, className, {
    [CSS.DarkTranslucentOverlay]: theme === 'dark-translucent',
  });

  if (inBrowser) {
    return (
      <>
        {ReactDOM.createPortal(
          <div onClick={onClick} className={classes}>
            {children}
          </div>,
          document.getElementById('overlay-container') || document.body,
        )}
      </>
    );
  } else {
    return null;
  }
}

export default Overlay;
