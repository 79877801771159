'use client';

import clsx from 'clsx';
import { useInView } from 'framer-motion';
import { useMemo, useRef } from 'react';

import CSS from './LocalsRow.module.scss';

interface LocalsRowProps {
  allLocals: {
    name: string;
    personalTitle: string;
    cloudinaryId: string;
  }[];
}

const IMAGE_SIZE = 52;
const CLOUDINARY_PATH = `https://withlocals-com-res.cloudinary.com/image/upload/f_auto,q_auto,g_face,ar_1,c_thumb,z_0.6,w_${IMAGE_SIZE},h_${IMAGE_SIZE},dpr_`;

function LocalsRow({ allLocals }: LocalsRowProps) {
  const { locals } = useMemo(() => {
    const locals = allLocals.slice(0, 2);
    return { locals };
  }, [allLocals]);

  const ref = useRef(null);
  const inView = useInView(ref, { once: true });

  if (allLocals.length === 0) {
    return null;
  }

  return (
    <div className={CSS.LocalsRow} ref={ref}>
      {locals.map(({ cloudinaryId, personalTitle, name }, index: number) => {
        return (
          <div
            className={clsx(CSS.LocalImageContainer, {
              [CSS.ImageAnimation]: inView,
            })}
            key={`local-image-${index}`}
          >
            <img
              srcSet={`${CLOUDINARY_PATH}1.0/${cloudinaryId} 1x, ${CLOUDINARY_PATH}2.0/${cloudinaryId} 2x,`}
              width={IMAGE_SIZE}
              height={IMAGE_SIZE}
              alt={`${name} - ${personalTitle}`}
              className={CSS.LocalImage}
              data-character={name.charAt(0)}
              title={name.charAt(0)}
              loading="lazy"
            />
          </div>
        );
      })}
      <span
        className={clsx(CSS.LocalsText, {
          [CSS.LabelAnimation]: inView,
        })}
      >
        {locals.length <= 1 && `With ${locals[0].name}`}
        {locals.length > 1 && `Choose your local`}
      </span>
    </div>
  );
}

export default LocalsRow;
