'use client';

import { ReactNode, useRef } from 'react';

import Carousel from '../Carousel';
import CarouselButtons from './CarouselButtons';
import CSS from './CarouselWithButtons.module.scss';

interface CarouselWithButtonsProp {
  as?: 'ul' | 'ol';
  className?: any;
  children: ReactNode;
  viewAllButton?: any;
  navigationTrackingName?: string;
  variant?: 'light' | 'dark';
  snapMode?: 'horizontal' | 'vertical' | 'none';
}

function CarouselWithButtons({
  as = 'ul',
  className,
  children,
  viewAllButton,
  navigationTrackingName,
  variant,
  snapMode,
}: CarouselWithButtonsProp) {
  const carouselRef = useRef<HTMLOListElement | HTMLUListElement>(null);

  return (
    <div className={CSS.CarouselContainer} aria-live="off">
      <Carousel
        as={as}
        className={className}
        ref={carouselRef}
        snapMode={snapMode}
      >
        {children}
      </Carousel>
      <nav className={CSS.ButtonContainer}>
        {viewAllButton}
        <CarouselButtons
          carouselRef={carouselRef}
          trackingName={navigationTrackingName}
          variant={variant}
        />
      </nav>
    </div>
  );
}

export default CarouselWithButtons;
