'use client';

import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import { useRef } from 'react';
import { MouseEvent } from 'react';

import CloseButton from './CloseButton';
import CSS from './Modal.module.scss';
import Overlay from './Overlay';

interface ModalProps {
  show: boolean;
  onClose?: () => void;
  className?: string;
  overlayClassName?: string;
  closeButtonClassName?: string;
  children: any;
  portalId?: string;
  fullscreen?: 'mobile' | 'all';
  hideClose?: boolean;
  disableOnOutsideClick?: boolean;
  isWide?: boolean;
  fullWidth?: boolean;
  theme?: 'light' | 'dark-translucent';
}

function Modal({
  show,
  onClose,
  className,
  overlayClassName,
  closeButtonClassName,
  children,
  portalId,
  fullscreen,
  hideClose,
  disableOnOutsideClick,
  isWide,
  fullWidth,
  theme,
}: ModalProps) {
  const container = useRef(null);
  const router = useRouter();
  const closeModal = () => {
    if (!!onClose && show) {
      onClose();
    } else if (!onClose && show) {
      router.back();
    }
  };

  const onModalClick = (event: MouseEvent) => {
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();

    if (!container?.current && !disableOnOutsideClick) {
      closeModal();
    }
  };

  if (!show) {
    return null;
  }

  return (
    <Overlay
      onEsc={closeModal}
      className={clsx(overlayClassName, {
        [CSS.RemoveOverlayBackground]: fullscreen === 'all',
        [CSS.RemoveOverlayBackgroundMobile]: fullscreen === 'mobile',
      })}
      onClick={onModalClick}
      portalId={portalId || 'dialogPortal'}
      theme={theme}
    >
      <dialog
        ref={container}
        open={show}
        className={clsx(CSS.Modal, className, {
          [CSS.ModalFullscreen]: fullscreen === 'all',
          [CSS.ModalFullscreenMobile]: fullscreen === 'mobile',
          [CSS.ModalWide]: isWide,
          [CSS.TransparentModal]: theme === 'dark-translucent',
          [CSS.ModalFullWidth]: fullWidth,
        })}
      >
        {!hideClose && (
          <CloseButton onClick={closeModal} className={closeButtonClassName} />
        )}
        {children}
      </dialog>
    </Overlay>
  );
}

export default Modal;
