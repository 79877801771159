import clsx from 'clsx';

import CSS from './LoadingDots.module.scss';

type LoadingDotsProps = {
  inline?: boolean;
};

function LoadingDots({ inline = false }: LoadingDotsProps) {
  return (
    <>
      <span className={clsx(CSS.LoadingDot, inline && CSS.LoadingDotInline)}>
        ·
      </span>
      <span className={clsx(CSS.LoadingDot, inline && CSS.LoadingDotInline)}>
        ·
      </span>
      <span className={clsx(CSS.LoadingDot, inline && CSS.LoadingDotInline)}>
        ·
      </span>
    </>
  );
}

export default LoadingDots;
