import IconType from '../../IconType';
import SVGIcon from '../../SVGIcon';
import CSS from './ArrowIcon.module.scss';

type ArrowIconProps = {
  variant?: 'black' | 'pink' | 'purple' | 'dark-green' | 'gray' | 'white';
} & IconType;

function ArrowIcon({ direction = 'right', variant, ...props }: ArrowIconProps) {
  return (
    <SVGIcon
      {...props}
      size={props.size ?? 24}
      direction={direction}
      viewBox="0 0 24 24"
      rotationOffset={0}
    >
      <path
        d="M6.54092559,3.13211774 C7.2963861,2.3262932 8.56205771,2.28546508 9.36788226,3.04092559 L17.3678823,10.5409256 C18.2107059,11.3310728 18.2107059,12.6689272 17.3678823,13.4590744 L9.36788226,20.9590744 C8.56205771,21.7145349 7.2963861,21.6737068 6.54092559,20.8678823 C5.78546508,20.0620577 5.8262932,18.7963861 6.63211774,18.0409256 L13.075,11.9999935 L6.63211774,5.95907441 C5.86870502,5.24337498 5.79188188,4.06975755 6.42825515,3.26303321 L6.54092559,3.13211774 Z"
        data-variant={variant}
        className={CSS.Arrow}
      />
    </SVGIcon>
  );
}

export default ArrowIcon;
