import clsx from 'clsx';
import { ReactNode } from 'react';

import TrackedLink from '@withlocals/analytics/TrackedLink';

import ChevronIcon from '../../icons/symbols/full-color/ChevronIcon';
import CSS from './ViewAllButton.module.scss';

interface ViewAllButtonProps {
  trackingName: string;
  children: ReactNode;
  href: string;
  className?: any;
  hideButton?: boolean;
}

function ViewAllButton({
  trackingName,
  children,
  className,
  href,
  hideButton,
}: ViewAllButtonProps) {
  if (hideButton) {
    return null;
  }

  const classes = clsx(CSS.ViewAllButton, {
    [className]: className,
  });

  return (
    <TrackedLink href={href} className={classes} trackingName={trackingName}>
      {children}{' '}
      <ChevronIcon
        variant="pink"
        className={CSS.ChevronIcon}
        width={16}
        height={16}
      />
    </TrackedLink>
  );
}

export default ViewAllButton;
