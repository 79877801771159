import clsx from 'clsx';

import CrossIcon from '../icons/symbols/full-color/CrossIcon';
import CSS from './CloseButton.module.scss';

type CloseButtonProps = {
  onClick: () => void;
  className?: string;
};

function CloseButton({ onClick, className }: CloseButtonProps) {
  return (
    <button
      className={clsx(CSS.CloseButton, className)}
      onClick={onClick}
      aria-label="close"
    >
      <CrossIcon size={14} variant="black" className={CSS.CloseIcon} />
    </button>
  );
}

export default CloseButton;
