'use client';

import { motion } from 'framer-motion';

import CSS from './CurrencyOption.module.scss';

const itemAnimation = {
  visible: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.01,
      duration: 0.2,
      ease: 'easeOut',
    },
  }),
  hidden: { opacity: 0, y: -100 },
};

function CurrencyOption({
  code,
  name,
  symbol,
  selected,
  index,
}: {
  code: string;
  name: string;
  symbol: string;
  selected?: boolean;
  index: number;
}) {
  return (
    <motion.li
      key={code}
      custom={index}
      initial="hidden"
      animate="visible"
      variants={itemAnimation}
    >
      <label className={CSS.CurrencyOption}>
        <input
          type="radio"
          name="currency"
          className={CSS.OptionRadio}
          defaultChecked={selected}
          value={code}
        />
        <div className={CSS.OptionText}>
          <span className={CSS.OptionName}>{name}</span>
          <span className={CSS.OptionCode}>
            {code} - {symbol}
          </span>
        </div>
      </label>
    </motion.li>
  );
}

export default CurrencyOption;
