import clsx from 'clsx';
import { forwardRef } from 'react';

import CSS from './Card.module.scss';

interface CardProps {
  children: any;
  as?: any;
  className?: string;
  moreDepth?: boolean;
  noHover?: boolean;
  noShadow?: boolean;
  [key: string]: any;
}

const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      className,
      as: Component = 'div',
      moreDepth,
      noHover,
      noShadow,
      ...rest
    }: CardProps,
    ref: any,
  ) => {
    const classes = clsx(CSS.Card, className, {
      [CSS.CardMoreDepth]: moreDepth,
      [CSS.NoHover]: noHover,
      [CSS.NoShadow]: noShadow,
    });
    return (
      <Component className={classes} ref={ref} {...rest}>
        {children}
      </Component>
    );
  },
);

Card.displayName = 'Card';

export default Card;
