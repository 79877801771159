import { useMemo } from 'react';

import CSS from './ExperienceImage.module.scss';

interface ExperienceImageProps {
  cloudinaryId: string;
  title: string;
  isWider?: boolean;
}

const IMAGE_SIZE = {
  default: {
    width: 286,
    height: 200,
  },
  wider: {
    width: 388,
    height: 200,
  },
};

const CLOUDINARY_PATH =
  'https://withlocals-com-res.cloudinary.com/image/upload/c_fill,f_auto,g_faces,q_auto,';

function getImagePath({ width, height, dpr, cloudinaryId }: any): string {
  return `${CLOUDINARY_PATH}w_${width},h_${height},dpr_${dpr}/${cloudinaryId}`;
}

function getImageSourceSet({ width, height, cloudinaryId }: any) {
  return `${getImagePath({
    width,
    height,
    dpr: '1.0',
    cloudinaryId,
  })} 1x, ${getImagePath({
    width,
    height,
    dpr: '2.0',
    cloudinaryId,
  })} 2x`;
}

function ExperienceImage({
  cloudinaryId,
  title,
  isWider = false,
}: ExperienceImageProps) {
  const { width, height, srcSet } = useMemo(() => {
    const { width, height } = isWider ? IMAGE_SIZE.wider : IMAGE_SIZE.default;
    const srcSet = getImageSourceSet({ width, height, cloudinaryId });
    return {
      width,
      height,
      srcSet,
    };
  }, [cloudinaryId, isWider]);

  return (
    <div className={CSS.ImageContainer}>
      <img
        srcSet={srcSet}
        alt={title}
        className={CSS.Image}
        title={title}
        loading="lazy"
        width={width}
        height={height}
      />
    </div>
  );
}

export default ExperienceImage;
