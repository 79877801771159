import IconType from '../../IconType';
import SVGIcon from '../../SVGIcon';
import CSS from './CrossIcon.module.scss';

type CrossIconProps = {
  variant?: 'error' | 'black' | 'white';
} & IconType;

function CrossIcon({ variant, ...props }: CrossIconProps) {
  return (
    <SVGIcon {...props} size={props.size ?? 24} viewBox="0 0 24 24">
      <path
        d="M6.47498954,3.59543331 L11.9996946,9.11922237 L17.5250105,3.59543331 C18.3189215,2.80152223 19.6075003,2.80152223 20.4034471,3.59543331 C21.1973582,4.39138007 21.1973582,5.67995883 20.4034471,6.47386992 L14.8781313,11.997659 L20.4030399,17.5234837 C21.1989867,18.3194305 21.1989867,19.6059736 20.4030399,20.4019203 C20.0060844,20.7988759 19.484953,20.9983715 18.9638216,20.9983715 C18.4426903,20.9983715 17.9215589,20.7988759 17.5246033,20.4019203 L11.9996946,14.8760956 L6.47539667,20.4019203 C6.07844113,20.7988759 5.55730975,20.9983715 5.03617837,20.9983715 C4.51504699,20.9983715 3.99391561,20.7988759 3.59696007,20.4019203 C2.80101331,19.6059736 2.80101331,18.3194305 3.59696007,17.5234837 L9.12125804,11.997659 L3.59655293,6.47386992 C2.80264185,5.67995883 2.80264185,4.39138007 3.59655293,3.59543331 C4.39249969,2.80152223 5.68107845,2.80152223 6.47498954,3.59543331 Z"
        data-variant={variant}
        className={CSS.Cross}
      />
    </SVGIcon>
  );
}

export default CrossIcon;
