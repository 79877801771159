import clsx from 'clsx';

import CSS from './ModalHeader.module.scss';

interface ModalHeader {
  children: any;
  hasBorder?: boolean;
  className?: string;
  variant?: 'default' | 'sandstone';
}

function ModalHeader({
  children,
  hasBorder,
  className,
  variant = 'default',
  ...rest
}: ModalHeader) {
  return (
    <div
      className={clsx(CSS.ModalHeader, className, {
        [CSS.ModalHeaderBorder]: hasBorder,
        [CSS.ModalHeaderSandstone]: variant === 'sandstone',
      })}
      {...rest}
    >
      {children}
    </div>
  );
}

export default ModalHeader;
