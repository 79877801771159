import addYears from 'date-fns/addYears';
import format from 'date-fns/format';
import { ItemList, ListItem, Product, WithContext } from 'schema-dts';

function getProductListSchema({ products }: { products: any[] }) {
  const productListSchema: WithContext<ItemList> = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: products.map((product, index) =>
      getProductListItemSchema({ product, position: index + 1 }),
    ),
  };

  return productListSchema;
}

function getProductListItemSchema({
  product,
  position,
}: {
  product: any;
  position: number;
}) {
  const productListItemSchema: WithContext<ListItem> = {
    '@context': 'https://schema.org',
    '@type': 'ListItem',
    position: position,
    item: getProductSchema(product),
  };

  return productListItemSchema;
}

function getProductSchema(product: any) {
  const productSchema: WithContext<Product> = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: product.title,
    image: `https://withlocals-com-res.cloudinary.com/image/upload/c_fill,f_auto,fl_progressive,g_auto,q_auto,w_500/${product.cloudinaryId}`,
    description: product?.teaser || 'Tour with locals',
    productID: `withlocals:experience:${product.id}`,
    identifier: `withlocals:experience:${product.id}`,
    mpn: `withlocals:experience:${product.id}`,
    sku: product.id,
    url: `https://www.withlocals.com/experience/${product.slug}/`,
    aggregateRating:
      product?.nrOfReviews > 0
        ? {
            '@type': 'AggregateRating',
            ratingValue: product?.rating || 5,
            reviewCount: product?.nrOfReviews,
            worstRating: 0.5,
            bestRating: 5,
          }
        : undefined,
    offers: {
      '@type': 'Offer',
      availability: 'https://schema.org/OnlineOnly',
      price: product?.basePrice || product?.price,
      priceCurrency: 'EUR',
      url: `https://www.withlocals.com/experience/${product.slug}/`,
      priceValidUntil: format(addYears(new Date(), 1), 'yyyy-MM-dd'),
    },
    brand: {
      '@type': 'Brand',
      name: 'Withlocals',
      url: 'https://www.withlocals.com/',
    },
  };

  return productSchema;
}

export default getProductListSchema;
