import { useMemo } from 'react';

import StarHalfIcon from '../icons/symbols/full-color/StarHalfIcon';
import StarIcon from '../icons/symbols/full-color/StarIcon';
import CSS from './StarRating.module.scss';

type StarRatingProps = {
  rating: number;
  size?: number;
  collapse?: boolean;
};

function StarRating({ rating, size = 12, collapse = false }: StarRatingProps) {
  const { fullStars, emptyStars, displayHalfStar } = useMemo(() => {
    const roundedRating = Math.round(rating * 2) / 2;
    const fullStars = collapse ? 1 : Math.floor(roundedRating);
    const emptyStars = collapse ? 0 : 5 - Math.ceil(roundedRating);
    const displayHalfStar = collapse ? false : roundedRating % 1 !== 0;

    return { fullStars, emptyStars, displayHalfStar };
  }, [rating, collapse]);

  return (
    <div className={CSS.StarRating}>
      {Array.from({ length: fullStars }).map((_, index) => (
        <StarIcon size={size} key={`full-star-${index}`} variant="pink" />
      ))}
      {displayHalfStar && <StarHalfIcon size={size} variant="pink" />}
      {Array.from({ length: emptyStars }).map((_, index) => (
        <StarIcon key={`empty-star-${index}`} size={size} variant="pink-50" />
      ))}
    </div>
  );
}

export default StarRating;
