'use client';

import { FormEvent, useState } from 'react';

import useCurrencyOptions from 'common-toolbox/src/hooks/useCurrencyOptions';
import { Currency, EUR_CURRENCY } from 'common-toolbox/src/types/currency';

import CSS from './CurrencyForm.module.scss';
import CurrencyOption from './CurrencyOption';

function CurrencyForm({
  defaultCurrency = EUR_CURRENCY,
  onSave,
}: {
  defaultCurrency: Currency;
  onSave: (currency: any) => void;
}) {
  const [moreCurrencyOptions, setMoreCurrencyOptions] = useState(false);
  const { popularCurrencies, otherCurrencies } =
    useCurrencyOptions(defaultCurrency);
  const onSaveCurrency = (event: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();
    const data = new FormData(event?.currentTarget);
    const currency = data.get('currency');
    onSave(currency);
  };

  return (
    <form onSubmit={onSaveCurrency} className={CSS.CurrencyForm}>
      <ol className={CSS.CurrencyList}>
        {popularCurrencies
          .sort((a) => (a.code === defaultCurrency.code ? -1 : 0))
          .map((currency, index) => (
            <CurrencyOption
              key={currency.code}
              index={index}
              code={currency.code}
              name={currency.name}
              symbol={currency.symbol_native}
              selected={
                currency.code === defaultCurrency.code ? true : undefined
              }
            />
          ))}
        {moreCurrencyOptions &&
          otherCurrencies.map((currency, index) => (
            <CurrencyOption
              key={currency.code}
              index={index}
              code={currency.code}
              name={currency.name}
              symbol={currency.symbol_native}
            />
          ))}
      </ol>
      <div className={CSS.Buttons}>
        <button
          type="button"
          onClick={() => setMoreCurrencyOptions(!moreCurrencyOptions)}
          className={CSS.ToggleButton}
        >
          {moreCurrencyOptions ? 'Show less' : 'More options'}
        </button>
        <button type="submit" className={CSS.SaveButton}>
          Save Currency
        </button>
      </div>
    </form>
  );
}

export default CurrencyForm;
