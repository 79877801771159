'use client';

import { motion } from 'framer-motion';

import Modal from 'common-toolbox/src/components/Modal';
import ModalHeader from 'common-toolbox/src/components/Modal/ModalHeader';
import { Currency, EUR_CURRENCY } from 'common-toolbox/src/types/currency';

import CurrencyForm from '../CurrencyForm';
import CSS from './CurrencySelectModal.module.scss';

function CurrencySelectModal({
  defaultCurrency = EUR_CURRENCY,
  onClose,
  onSave,
}: {
  defaultCurrency?: Currency;
  onClose: () => void;
  onSave: (currency: any) => void;
}) {
  return (
    <Modal
      onClose={onClose}
      show
      className={CSS.Modal}
      fullscreen="mobile"
      isWide
    >
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className={CSS.Modal}
      >
        <ModalHeader>
          <h2>Choose your currency</h2>
        </ModalHeader>
        <CurrencyForm defaultCurrency={defaultCurrency} onSave={onSave} />
      </motion.div>
    </Modal>
  );
}

export default CurrencySelectModal;
