'use client';

import { useContext, useState } from 'react';

import LoadingDots from 'common-toolbox/src/components/LoadingDots';
import { CurrencyContext } from 'common-toolbox/src/context/CurrencyContext';

import ArrowIcon from '../../icons/symbols/full-color/ArrowIcon';
import CurrencySelectModal from '../CurrencySelectModal';
import CSS from './CurrencySelect.module.scss';

const getCurrencyLabel = (currency: any) => {
  if (!currency) {
    return <LoadingDots inline />;
  }
  return (
    <>
      {currency?.code} - {currency?.symbol_native}
    </>
  );
};

function CurrencySelect() {
  const { state, actions } = useContext(CurrencyContext);
  const [showModal, setShowModal] = useState(false);
  const onShowModal = () => setShowModal(true);
  const onCloseModal = () => setShowModal(false);

  const onSave = (currency: any) => {
    actions.setCurrency(currency);
    onCloseModal();
  };

  return (
    <div className={CSS.CurrencyContainer}>
      <button onClick={onShowModal} className={CSS.CurrencySelect}>
        <>
          {getCurrencyLabel(state.currency)}
          <ArrowIcon
            className={CSS.ArrowIcon}
            direction="down"
            variant="gray"
            size={12}
          />
        </>
      </button>
      {showModal && (
        <CurrencySelectModal
          defaultCurrency={state.currency}
          onClose={onCloseModal}
          onSave={onSave}
        />
      )}
    </div>
  );
}

export default CurrencySelect;
