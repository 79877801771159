'use client';

import throttle from 'lodash.throttle';
import { useEffect, useState } from 'react';

function useNavButtonsDisabled(listRef: any) {
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

  useEffect(() => {
    const listElement = listRef.current;

    const throttleScroll = throttle(
      () => {
        if (listElement && listElement.scrollLeft === 0) {
          setPrevButtonDisabled(() => true);
        } else {
          setPrevButtonDisabled(() => false);
        }

        if (
          Math.ceil(listElement.offsetWidth + listElement.scrollLeft) >=
          listElement.scrollWidth
        ) {
          setNextButtonDisabled(() => true);
        } else {
          setNextButtonDisabled(() => false);
        }
      },
      200,
      { leading: false, trailing: true },
    );

    throttleScroll();
    listElement.addEventListener('scroll', throttleScroll, { passive: true });
    window.addEventListener('resize', throttleScroll, { passive: true });

    return () => {
      listElement.removeEventListener('scroll', throttleScroll);
      window.removeEventListener('resize', throttleScroll);
      throttleScroll.cancel();
    };
  }, [listRef]);

  return { prevButtonDisabled, nextButtonDisabled };
}

export default useNavButtonsDisabled;
