import clsx from 'clsx';
import { ReactNode, forwardRef } from 'react';

import CSS from './Carousel.module.scss';

interface CarouselProp {
  as?: 'ul' | 'ol';
  className?: any;
  children: ReactNode;
  snapMode?: 'horizontal' | 'vertical' | 'none';
}

const Carousel = forwardRef(function Carousel(
  {
    as: Component = 'ul',
    className,
    children,
    snapMode = 'horizontal',
  }: CarouselProp,
  ref: any,
) {
  const classes = clsx(CSS.Carousel, className, {
    [CSS.HorizontalSnappedCarousel]: snapMode === 'horizontal',
    [CSS.VerticalSnappedCarousel]: snapMode === 'vertical',
  });

  return (
    <Component className={classes} ref={ref}>
      {children}
    </Component>
  );
});

export default Carousel;
