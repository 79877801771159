import clsx from 'clsx';
import { ReactNode } from 'react';

import CSS from './Section.module.scss';

export interface SectionProps {
  as?: 'section' | 'header' | 'footer' | 'aside';
  variant?: 'default' | 'sandstone' | 'purple' | 'dark-green' | 'misty-green';
  noSpace?: 'top' | 'bottom' | 'all';
  noPadding?: boolean;
  className?: any;
  children: ReactNode | JSX.Element;
}

function Section({
  as: Component = 'section',
  variant = 'default',
  noSpace,
  noPadding,
  className,
  children,
}: SectionProps) {
  const classes = clsx(CSS.Section, className, {
    [CSS.VariantSandstone]: variant === 'sandstone',
    [CSS.VariantPurple]: variant === 'purple',
    [CSS.VariantDarkGreen]: variant === 'dark-green',
    [CSS.VariantMistyGreen]: variant === 'misty-green',
    [CSS.NoTopSpace]: noSpace === 'top',
    [CSS.NoBottomSpace]: noSpace === 'bottom',
    [CSS.NoSpace]: noSpace === 'all',
    [CSS.NoPadding]: noPadding,
  });
  return <Component className={classes}>{children}</Component>;
}

export default Section;
