import getProductListSchema from 'common-toolbox/src/helpers/structuredData/getProductListSchema';

function ProductListSchema({ products }: { products: any[] }) {
  const jsonLd = getProductListSchema({ products });

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  );
}

export default ProductListSchema;
