import {
  Currency,
  CurrencyList,
  EUR_CURRENCY,
} from 'common-toolbox/src/types/currency';

const popularCurrencies = CurrencyList.filter(
  (currency) => currency.popular,
).sort((a, b) => a.name.localeCompare(b.name));
const otherCurrencies = CurrencyList.filter(
  (currency) => !currency.popular,
).sort((a, b) => a.name.localeCompare(b.name));

function useCurrencyOptions(currency: Currency = EUR_CURRENCY) {
  const selectedCurrency = currency;
  return {
    selectedCurrency,
    popularCurrencies: [
      selectedCurrency,
      ...popularCurrencies.filter(
        (currency) => currency.code !== selectedCurrency.code,
      ),
    ],
    otherCurrencies: otherCurrencies.filter(
      (currency) => currency.code !== selectedCurrency.code,
    ),
    currencies: CurrencyList,
  };
}

export default useCurrencyOptions;
