import StarRating from 'common-toolbox/src/components/StarRating';

import CSS from './ExperienceDetails.module.scss';

interface ExperienceDetailsProps {
  rating: number;
  nrOfReviews: number;
  duration: string;
  category: string;
  price: number;
  formattedPrice: string;
}

function getReviewsText(nrOfReviews: number) {
  if (nrOfReviews === 0) {
    return null;
  }
  return `${nrOfReviews} review${nrOfReviews > 1 ? 's' : ''}`;
}

function ExperienceDetails({
  rating,
  nrOfReviews,
  duration,
  category,
  formattedPrice,
}: ExperienceDetailsProps) {
  return (
    <div className={CSS.ExperienceDetails}>
      <div className={CSS.Reviews}>
        <span className={CSS.Rating}>{rating.toFixed(1)}</span>
        <StarRating rating={rating} /> {getReviewsText(nrOfReviews)}
      </div>
      <div className={CSS.DurationCategory}>
        {duration} • {category}
      </div>
      <div className={CSS.Price}>from {formattedPrice} per person</div>
    </div>
  );
}

export default ExperienceDetails;
