import clsx from 'clsx';

import TrackedLink from '@withlocals/analytics/TrackedLink';

import Card from '../Card';
import CSS from './ExperienceCardCompact.module.scss';
import ExperienceDetails from './ExperienceDetails';
import ExperienceImage from './ExperienceImage';
import LocalsRow from './LocalsRow';

interface ExperienceCardCompactProps {
  title: string;
  rating: number;
  nrOfReviews: number;
  price: number;
  formattedPrice: string;
  cloudinaryId: string;
  slug: string;
  duration: string;
  category: string;
  locals: { name: string; personalTitle: string; cloudinaryId: string }[];
  variant?: 'default' | 'wider';
  className?: string;
}

function ExperienceCardCompact({
  title,
  rating,
  nrOfReviews,
  price,
  formattedPrice,
  cloudinaryId,
  slug,
  duration,
  category,
  locals,
  variant = 'default',
  className,
}: ExperienceCardCompactProps) {
  const classes = clsx(CSS.ExperienceCardCompact, className, {
    [CSS.ExperienceCardWider]: variant === 'wider',
  });

  return (
    <Card className={classes} as="li">
      <ExperienceImage
        cloudinaryId={cloudinaryId}
        title={title}
        isWider={variant === 'wider'}
      />
      <LocalsRow allLocals={locals} />
      <div className={CSS.BottomCard}>
        <h3 className={CSS.Title}>
          <TrackedLink
            href={`/experience/${slug}`}
            className={CSS.ExperienceLink}
            trackingName="experience-card-compact"
          >
            {title}
          </TrackedLink>
        </h3>
        <ExperienceDetails
          rating={rating}
          nrOfReviews={nrOfReviews}
          duration={duration}
          category={category}
          price={price}
          formattedPrice={formattedPrice}
        />
      </div>
    </Card>
  );
}

export default ExperienceCardCompact;
