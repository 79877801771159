import IconType from '../../IconType';
import SVGIcon from '../../SVGIcon';
import CSS from './StarHalfIcon.module.scss';

type StarHalfIconProps = {
  variant?: 'pink' | 'purple' | 'green';
} & IconType;

function StarHalfIcon({ variant, ...props }: StarHalfIconProps) {
  return (
    <SVGIcon {...props} size={props.size ?? 24} viewBox="0 0 24 24">
      <path
        d="M12.0199778,2 C11.6154635,1.99299798 11.2063426,2.2038964 11.0724708,2.6328725 L9.15889345,8.81003584 L2.97536615,8.81003584 C2.55207737,8.81003584 2.17755457,9.09677419 2.04686172,9.5186892 C1.91811951,9.9406042 2.0605162,10.4034818 2.40187812,10.6635945 L7.40526858,14.4813108 L5.49364181,20.6584741 C5.3648996,21.0824373 5.50729629,21.5432668 5.84865821,21.8033794 C6.19197077,22.0634921 6.65232171,22.0634921 6.99563427,21.8033794 L11.9990246,17.9856631 L12.0199778,2 Z"
        data-variant={variant}
        className={CSS.FilledHalf}
      />
      <path
        d="M12.0192863,2.0001652 C12.4107192,2.00687172 12.7979882,2.21777416 12.9275291,2.6328725 L14.8411065,8.81003584 L21.0246339,8.81003584 C21.4479226,8.81003584 21.8224454,9.09677419 21.9531383,9.5186892 C22.0818805,9.9406042 21.9394838,10.4034818 21.5981219,10.6635945 L16.5947314,14.4813108 L18.5044075,20.6584741 C18.6351004,21.0824373 18.4927037,21.5432668 18.1513418,21.8033794 C17.9796855,21.9344598 17.7787696,22 17.5778538,22 C17.3749872,22 17.176022,21.9344598 17.0043657,21.8033794 L11.9990246,17.9856631 L12.0192863,2.0001652 Z"
        data-variant={variant}
        className={CSS.EmptyHalf}
      />
    </SVGIcon>
  );
}

export default StarHalfIcon;
