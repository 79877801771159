'use client';

import ChevronIcon from '../../icons/symbols/full-color/ChevronIcon';
import CSS from './ScrollToTopButton.module.scss';

const ScrollToTopButton = () => {
  const onClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className={CSS.Container}>
      <button
        className={CSS.Button}
        onClick={onClick}
        aria-label={'Scroll to top'}
      >
        <ChevronIcon size={16} direction="up" variant="white" />
      </button>
    </div>
  );
};

export default ScrollToTopButton;
